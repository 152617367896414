import env from '@js/utils/env';
import { gsap } from 'gsap';

const CSS_OPENED = '-open';
const CSS_CLOSED = 'hidden';

class Accordions {
    constructor() {
        document.querySelectorAll('[data-accordion]').forEach((accordion) => {
            this._initAccordion(accordion);
        });
    }
    _initAccordion(accordion) {
        const items = accordion.querySelectorAll('[data-accordion-item]');

        // Loop through all accordion items
        items.forEach((item) => {
            const header = item.querySelector('[data-accordion-header]');
            const panel = item.querySelector('[data-accordion-panel]');

            let opened = false;
            let animating = false;

            header.addEventListener('click', (e) => {
                if (!animating) {
                    opened = !opened;

                    animating = true;

                    if (opened) {
                        item.classList.add(CSS_OPENED);
                        panel.classList.remove(CSS_CLOSED);
                        header.classList.add(CSS_OPENED);

                        // WCAG
                        header.setAttribute('aria-expanded', true);

                        const h = panel.offsetHeight;
                        gsap.fromTo(
                            panel,
                            { height: 0, overflow: 'hidden' },
                            {
                                height: h,
                                duration: env.reducedMotion ? 0 : 0.35,
                                clearProps: 'all',
                                onComplete: () => {
                                    animating = false;
                                },
                            }
                        );
                    } else {
                        item.classList.remove(CSS_OPENED);
                        header.classList.remove(CSS_OPENED);

                        gsap.set(panel, {
                            overflow: 'hidden',
                        });
                        gsap.to(panel, {
                            height: 0,
                            duration: env.reducedMotion ? 0 : 0.35,
                            clearProps: 'all',
                            onComplete: () => {
                                animating = false;
                                panel.classList.add(CSS_CLOSED);

                                // WCAG
                                header.setAttribute('aria-expanded', false);
                            },
                        });
                    }
                }
            });
        });
    }
}

export default new Accordions();
