class TeamFilter {
    constructor() {
        // CSS classes
        this.hiddenClass = 'hidden';
        this.activeClass = 'active';
        this.teamMembersClass = 'team-member';
        this.buttonsClass = 'team-filter-button';

        // Convert node lists to arrays
        this.buttons = Array.from(
            document.querySelectorAll(`.${this.buttonsClass}`)
        );
        this.teamMembers = Array.from(
            document.querySelectorAll(`.${this.teamMembersClass}`)
        );

        // Attach click event handlers to buttons
        this.buttons.forEach(button =>
            button.addEventListener('click', () => this._handleClick(button))
        );

        // Store the initially active button (if any)
        this.activeButton = document.querySelector(`.${this.buttonsClass}.${this.activeClass}`);
    }

    // Set the given button as active and deactivate the previous one
    _setActiveButton(button) {
        if (this.activeButton) {
            this.activeButton.classList.remove(this.activeClass);
        }

        this.activeButton = button;
        this.activeButton.classList.add(this.activeClass);
    }

    // Handle a button click event
    _handleClick(button) {
        // Do nothing if button is already active
        if (button.classList.contains(this.activeClass)) {
            return;
        }

        // Get the target value for the button
        const { target } = button.dataset;

        // Show/hide team members based on their targets attribute
        this.teamMembers.forEach(member => {
            const targets = member.dataset.targets.split(',');
            const isFiltered = targets.includes(target);
            const isShowAll = target === '';

            // Toggle the hidden class based on whether the member is filtered or not
            member.classList.toggle(this.hiddenClass, !isFiltered && !isShowAll);
        });

        // Set the clicked button as active and deactivate the previous one
        this._setActiveButton(button);
    }
}

export default new TeamFilter();
