import PageScrollingManager from '@js/modules/PageScrollingManager';
import {gsap} from 'gsap';

class UnderlineEffect {
	constructor() {
		this.underlineTargets = [];
	}

	start(section) {
		// Show interface here
		this.setup(section ?? document.body);
	}
	setup(section) {
		// Underline elements
		const underlineElements = section.querySelectorAll('[data-underline]');

		this.underlineTargets = [];

		underlineElements.forEach((element) => {
			const attributeValues = element
				.getAttribute('data-underline')
				.split(',');
			const delay = Number.parseFloat(attributeValues[0] || 0);
			const addClass = attributeValues[1] || '_animate';

			this.underlineTargets.push(
				PageScrollingManager.watchOnce(element, () => {
					gsap.delayedCall(delay, () => {
						element.classList.add(addClass);
					});
				})
			);
		});
	}
	clear() {
		this.underlineTargets.forEach((target) => {
			PageScrollingManager.unwatch(target);
		});
	}
}

export default new UnderlineEffect();
