class Forms {
    constructor() {
        // Find the corresponding Form
        let freeformsEls = document.querySelectorAll('form[data-freeform]');

        freeformsEls.forEach((form) => {
            var formHandle = form.getAttribute('data-handle');

            form.addEventListener('freeform-ready', (event) => {
                let freeform = event.target.freeform;

                freeform.setOption("errorClassBanner", ["text-message-error", "relative", "text-center", "mb-4"]);
                freeform.setOption("errorClassList", ["errors", "mt-1", "text-message-error", "text-tiny"]);
                freeform.setOption("errorClassField", ["border-red-700"]);
                freeform.setOption("successClassBanner", ["text-message-success", "relative", "text-center", "mb-4"]);

                // Get new session details for the form
                // This is good for other sessions for different users
                this._reset(form, formHandle);
            });

            form.addEventListener('freeform-render-success',  (event) => {
                // Reset form after submission
                this._reset(form, formHandle);
            });

            form.addEventListener('freeform-stripe-styling', (event) => {
                event.detail.base = {
                    fontSize: "16px",
                    fontFamily: "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"",
                }
            });
        });
    }
    _reset(form, formHandle) {
        // Reset form fields for AJAX resubmission
        fetch('/dynamic?form='+formHandle, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
                // Update the Form Hash
                form.querySelector('input[name=formHash]').value = json.hash;

                // Update the JS Honeypot (ONLY when JS Enhancement setting is ON)
                let honeypot = json.honeypot;
                if (honeypot) {
                    let honeypotInput = form.querySelector('input[name^="freeform_form_handle"]');
                    honeypotInput.setAttribute('id', honeypot.name);
                    honeypotInput.setAttribute('name', honeypot.name);
                    honeypotInput.value = honeypot.hash;
                }

                // Locate and update the CSRF input
                let csrf = json.csrf;
                form.querySelector('input[name=' + csrf.name + ']').value = csrf.value;
            })
            .catch(error => {
                console.error(error);
            });
    }
}

export default new Forms();
