class UnderlineEffectHover {
    constructor() {
        this.attr = 'data-underline-hover';
    }

    start() {
        this.setup();
    }

    setup() {
        // Underline elements
        const underlineHoverElements = document.querySelectorAll(`[${this.attr}]`);

        underlineHoverElements.forEach((element) => {
            const selector = element.getAttribute(this.attr);

            const addClass = () => {
                const underlineElements = element.querySelectorAll(selector);
                underlineElements.forEach((el) => {
                    el.classList.add('-animate');
                });
            };

            const removeClass = () => {
                const underlineElements = element.querySelectorAll(selector);
                underlineElements.forEach((el) => {
                    el.classList.remove('-animate');
                });
            };

            element.addEventListener('mouseenter', addClass);
            element.addEventListener('mouseleave', removeClass);
        });
    }
}

export default new UnderlineEffectHover();
