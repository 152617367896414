class Hovers {
    constructor() {
        this._initAllHovers();
    }

    _initHover(target) {
        target.addEventListener('mouseenter', () => {
            target.classList.add('_hover');
        });
        target.addEventListener('mouseleave', () => {
            target.classList.remove('_hover');
        });
    }

    _initAllHovers() {
        document.querySelectorAll('[data-hover]').forEach((target) => {
            this._initHover(target);
        });
    }

    initHover(target = null) {
        if (target) {
            this._initHover(target);
        } else {
            this._initAllHovers();
        }
    }

    removeHover(target) {
        target.classList.remove('_hover');
        target.removeEventListener('mouseenter', this._initHover);
        target.removeEventListener('mouseleave', this._initHover);
    }
}

export default new Hovers();
