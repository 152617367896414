// Imports
import Headroom from "headroom.js";
import LazyLoad from "vanilla-lazyload";
import reframe from "reframe.js";
import Glide from "@glidejs/glide";
import MicroModal from "micromodal";

// Project utils
import env from '@js/utils/env';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import Magnets from '@js/modules/Magnets';
import Accordions from '@js/modules/Accordions';
import Hovers from '@js/modules/Hovers';
import Marquee from '@js/modules/Marquee';
import Navigation from '@js/modules/Navigation';
import Onloaders from '@js/modules/Onloaders';
import Parallax from '@js/modules/Parallax';
import ScrollFadeOuts from '@js/modules/ScrollFadeOuts';
import ScrollTo from '@js/modules/ScrollTo';
import Switchers from '@js/modules/Switchers';
import TeamFilter from '@js/modules/TeamFilter';
import UnderlineEffect from '@js/modules/UnderlineEffect';
import UnderlineEffectHover from '@js/modules/UnderlineEffectHover';

class Page {
    constructor() {

    }
    init() {
        PageScrolling.start();
    }
    domLoaded() {
        // If the user does not have reduced motion settings in the browser
        if (!env.reducedMotion){
            Appear.start();
            AppearParent.start();
            Magnets.update();
            Marquee.start();
            Parallax.start();
            UnderlineEffect.start();
            UnderlineEffectHover.start();
        }

        /**
         * Header - headroom
         */
        let headerElement = document.querySelector('.mn-hdr'),
            headroom  = new Headroom(headerElement, {
                offset: 100,
                tolerance: {
                    up: 8,
                    down: 0
                }
            });

        // Initialise
        headroom.init();

        /**
         * Lazy load
         */
        let lazyLoadInstance = new LazyLoad({
            threshold: 400
        });

        /**
         * Reframe JS
         * Make elements like iframes scale at a fixed ratio
         */
        reframe('iframe');

        /**
         * Sliders
         */
        // Disable slider controls if reach end
        function DisableControls(Glide, Components, Events) {
            const PREV_CONTROL_SELECTOR = '[data-glide-dir="<"]';
            const NEXT_CONTROL_SELECTOR = '[data-glide-dir=">"]';
            const component = {
                buildAfter() {
                    this.prevBtn = Components.Html.root.querySelector(PREV_CONTROL_SELECTOR);
                    this.nextBtn = Components.Html.root.querySelector(NEXT_CONTROL_SELECTOR);
                },
                handleDisable() {
                    const perView = Glide.settings.perView;
                    const slidesCount = Components.Html.slides.length;

                    this.prevBtn.disabled = (Glide.index <= 0);
                    this.nextBtn.disabled = (Glide.index >= slidesCount - perView);
                },
            };

            Events.on('build.after', function () {
                component.buildAfter();
                component.handleDisable();
            });
            Events.on('run.after', function () {
                component.handleDisable();
            });

            return component;
        }// END DisableControls

        // Full width slider
        const fullSliderElements = document.querySelectorAll('.slider-full');
        if (fullSliderElements.length) {
            fullSliderElements.forEach(slider => {
                let counterId = slider.getAttribute('data-slider-counter'),
                    counterElement = document.querySelector(counterId);

                let glide = new Glide(slider, {
                    type: 'slider',
                    startAt: 0,
                    perView: 1,
                    gap: 0,
                    autoplay: false,
                    animationDuration: env.reducedMotion ? 0 : 500,
                    rewind: false
                });

                glide.on(['mount.after', 'run'], function () {
                    let index = glide.index + 1,
                        slideNum = index < 10 ? '0'+index : index;

                    // Update slide counter
                    counterElement.innerHTML = slideNum;
                });

                glide.mount({
                    DisableControls: DisableControls
                });
            });
        }

        // Carousel slider
        const carouselElements = document.querySelectorAll('.carousel');
        if (carouselElements.length) {
            carouselElements.forEach(slider => {
                let glide = new Glide(slider, {
                    type: 'carousel',
                    startAt: 0,
                    perView: 2,
                    gap: '30px',
                    autoplay: false,
                    animationDuration: env.reducedMotion ? 0 : 500,
                    breakpoints: {
                        768: {
                            perView: 1
                        }
                    }
                });

                glide.mount();
            });
        }

        /**
         * Modals
         */
        MicroModal.init({
            openTrigger: 'data-modal-open',
            closeTrigger: 'data-modal-close',
            onShow: modal => {
                // Disable scrolling
                PageScrolling.lock();
            },
            onClose: modal => {
                // Enable scrolling
                PageScrolling.unlock();
            }
        });

        /**
         * Download resources
         */
        const downloadResourceElements = document.querySelectorAll('[data-resource-download]');
        downloadResourceElements.forEach((element) => {
            //const name = element.getAttribute('data-resource-download');
            const file = element.getAttribute('href');

            let delay = 0;

            element.addEventListener('click', (e) => {
                e.preventDefault();

                // Test if modal has been shown already
                const resourceModal = localStorage.getItem('resourceModal');

                // If not shown modal before
                if (!resourceModal) {
                    // Update resources modal title
                    // Enable this to add file name to modal heading
                    //document.getElementById('resource-modal-name').textContent = name;

                    // Show modal
                    MicroModal.show('resource-modal');
                    document.querySelector('[data-modal-close="resource-modal"]').addEventListener('click', function () {
                        MicroModal.close('resource-modal');
                    });

                    // Add item to local storage
                    localStorage.setItem('resourceModal', 1);

                    // Increase delay
                    delay = 1500;
                }

                // Download file
                setTimeout(function() {
                    window.open(file, '_blank');
                }, delay);
            });
        });

        /**
         * Remove elements
         */
        const removeElements = document.querySelectorAll('[data-remove-element]');

        removeElements.forEach((element) => {
            element.addEventListener('click', (e) => {
                let el = element.getAttribute('data-remove-element')

                // Remove element from DOM
                document.querySelector(el).remove();
            });
        });
    }
    windowLoad() {
        PageScrollingManager.update(true);

        // Load
        Onloaders.update();
        Switchers.update();
    }
}

export default new Page();
