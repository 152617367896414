class ScrollTo {
    constructor() {
        const attrName = 'data-scroll-to';

        document.querySelectorAll(`[${attrName}]`)?.forEach((element) => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                this.scrollTo(element.getAttribute(attrName));
            });
        });
    }

    scrollTo(attrs) {
        const [target, offset = 0] = attrs.split(',').map((a) => a.trim());

        let y = this._isNum(target) ? target : document.querySelector(target)?.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop);

        y += parseInt(offset);
        y = Math.max(0, y);

        window.scrollTo({
            top: y,
            behavior: 'smooth'
        });
    }

    _isNum(val) {
        return !isNaN(val);
    }
}

export default new ScrollTo();
