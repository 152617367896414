/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

import htmx from 'htmx.org';

import gsap from 'gsap';
gsap.defaults({
    overwrite: 'auto',
});

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Alpine.start();
window.Alpine = Alpine;

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import VhCalc from '@js/helpers/ViewHeightCalc';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import Forms from '@js/modules/Forms';
import Page from '@js/modules/Page';

window.TGD =
    window.TGD ||
    new (function TGD() {
        this.env = env;

        this.utils = utils;

        this.helpers = {
            DelayedCall: DelayedCall,
            VhCalc: VhCalc,
        };

        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Forms: Forms,
            Page: Page,
        };


        // HTMX
        htmx.config.requestClass = 'loading';

        // Reinit page after swap
        htmx.on('htmx:afterSettle', function(event) {
            window.TGD.modules.Page.init();
            window.TGD.modules.Page.domLoaded();

            // Reinit Alpine
            Alpine.start();

            // Scroll to top of search content so we aren't left at the bottom of the new page
            let searchEl = document.getElementById('search');
            if (searchEl) {
                let hdr = document.querySelector('.mn-hdr');
                window.scrollTo(0, searchEl.offsetTop - hdr.offsetHeight - 20);
            }
        });


        // Page init
        this.modules.Page.init();

        // Window Load
        window.addEventListener('load', e => {
            this.modules.Page.windowLoad();
        });


        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            this.modules.Page.domLoaded();
        });
    })();
