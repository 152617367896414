class Marquee {
    start() {
        const marquees = document.querySelectorAll('.marquee');

        marquees.forEach((marquee) => {
            const marqueeElementsDisplayed = parseInt(getComputedStyle(marquee).getPropertyValue('--marquee-elements-displayed'));
            const marqueeContent = marquee.querySelector('.marquee-content');
            const marqueeElements = marqueeContent.children;

            marquee.style.setProperty('--marquee-elements', marqueeElements.length);

            for (let i = 0; i < marqueeElementsDisplayed; i++) {
                marqueeContent.appendChild(marqueeElements[i].cloneNode(true));
            }
        });
    }
}

export default new Marquee();
