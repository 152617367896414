import env from '@js/utils/env';
import { gsap } from 'gsap';

const DEFAULT_MOVING = 20;
const DEFAULT_ROTATION = 5;

class Magnets {
    constructor() {
        this.update();
    }

    update() {
        document.querySelectorAll('[data-magnet]').forEach((element) => {
            const [moving = DEFAULT_MOVING, rotation = DEFAULT_ROTATION] = element
                .getAttribute('data-magnet')
                .split(',')
                .map((v) => parseFloat(v.trim()));

            element.removeAttribute('data-magnet');

            this.init(element, moving, rotation);
        });
    }

    init(
        element,
        moving = DEFAULT_MOVING,
        rotation = DEFAULT_ROTATION
    ) {
        if (env.isMobile) {
            return;
        }

        gsap.set(element, {
            transformPerspective: 300,
            'will-change': 'transform',
        });

        const movingAbs = Math.abs(moving);
        const halfMovingAbs = movingAbs / 2;

        let bBox;

        element.addEventListener('mouseenter', (e) => {
            bBox = element.getBoundingClientRect();
        });

        element.addEventListener('mousemove', (e) => {
            if (!bBox) {
                bBox = element.getBoundingClientRect();
            }
            const x = e.clientX - (bBox.left - halfMovingAbs);
            const y = e.clientY - (bBox.top - halfMovingAbs);

            const dx = x / (bBox.width + movingAbs) - 0.5;
            const dy = y / (bBox.height + movingAbs) - 0.5;

            gsap.to(element, {
                x: moving * dx,
                y: moving * dy,
                rotationY: rotation * dx,
                rotationX: -rotation * dy,
                duration: 0.15,
            });
        });

        element.addEventListener('mouseleave', (e) => {
            gsap.to(element, {
                x: 0,
                y: 0,
                rotationX: 0,
                rotationY: 0,
                ease: 'back.out',
                duration: 0.35,
            });
        });
    }
}

export default new Magnets();
