import env from '@js/utils/env';
import gsap from 'gsap';

const getAttributeValue = (a, d) => {
    const value = parseFloat(a.trim());
    return Number.isNaN(value) ? d : value;
};

class Switchers {
    constructor() {
        // Uncomment to have it load when ready
        // this.update();
    }

    init(el, debug) {
        if (el.switchersInited) {
            return;
        }

        const attribute = el.getAttribute('data-switch').split(',');
        const duration = getAttributeValue(attribute[0], 0.5);
        const delay = getAttributeValue(attribute[1], 1);
        const easing = attribute[2]?.trim() ?? '';
        const fadeEasing = attribute[3]?.trim() ?? '';
        const delayAppear = getAttributeValue(attribute[4], 0);
        const distance = getAttributeValue(attribute[5], 100);
        const maxRepeats = getAttributeValue(attribute[6], -1);

        if (debug) {
            console.log(el, duration, delay, easing);
        }

        const children = Array.from(el.children);

        let index = -1;
        let repeatsCounter = 0;
        let needWidth;

        const next = (immediate = false) => {
            repeatsCounter++;

            if (maxRepeats > 0 && repeatsCounter > maxRepeats) {
                return;
            }

            index = (index + 1) % children.length;
            const showChild = children[index];

            const tweenTime = immediate ? 0 : duration;
            gsap.to(children, {
                y: `-${distance}%`,
                duration: env.reducedMotion ? 0 : tweenTime,
                ease: easing,
            });
            gsap.to(children, {
                alpha: 0,
                duration: env.reducedMotion ? 0 : tweenTime,
                ease: fadeEasing,
            });

            // Remove active class from all children
            children.forEach((c) => c.classList.remove('_active'));

            gsap.fromTo(
                showChild,
                { y: `${distance}%` },
                {
                    y: '0%',
                    duration: env.reducedMotion ? 0 : tweenTime,
                    ease: easing,
                    delay: immediate ? 0 : delayAppear,
                }
            );
            gsap.fromTo(
                showChild,
                { alpha: 0 },
                {
                    alpha: 1,
                    duration: env.reducedMotion ? 0 : tweenTime,
                    ease: fadeEasing,
                    delay: immediate ? 0 : delayAppear,
                }
            );

            needWidth = showChild.offsetWidth;
            gsap.to(el, {
                width: needWidth,
                duration: env.reducedMotion ? 0 : tweenTime / 2,
                delay: immediate ? 0 : env.reducedMotion ? 0 : delayAppear,
            });
            showChild.classList.add('_active');
            gsap.delayedCall(delay + (immediate ? 0 : delayAppear), next);
        };

        next(true);

        gsap.fromTo(el, { alpha: 0 }, { alpha: 1 });

        window.addEventListener('resize', () => {
            const activeChild = el.querySelector('._active');
            const updateWidth = activeChild.offsetWidth;
            gsap.set(el, { width: updateWidth });
        });

        el.switchersInited = true;
    }

    update(debug) {
        const switchers = document.querySelectorAll('[data-switch]');
        switchers.forEach((el) => this.init(el, debug));
    }
}

export default new Switchers();